<template>
  <div ref="pdfContainer" class="pdfContainer">
    <p class="text-2xl mb-6">Stats mensuelles</p>
    <v-card class="mb-6 pa-2" outlined>
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>

    <template v-for="(site, index) in sites">
      <v-card class="mb-6 pa-2" v-if="site" outlined :key="site.name">
        <v-card-title>{{ site.name }}</v-card-title>
        <v-card-subtitle>CA</v-card-subtitle>
        <v-card-text>
          <v-data-table
            locale="fr-FR"
            :headers="headersCa"
            :items="itemsCa[site_id_key(site.id)]"
            :loading="loading"
            class="elevation-1"
            :hide-default-footer="true"
            :disable-pagination="true"
            :disable-filtering="true"
            :disable-sort="true"
          >
            <template v-slot:item.month="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                }"
              >
                {{ item.month }}
              </div>
            </template>
            <template v-slot:item.objective="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                }"
              >
                {{ item.objective }}
              </div>
            </template>
            <template v-slot:item.value="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                }"
              >
                {{ item.value }}
              </div>
            </template>
            <template v-slot:item.estimated="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                  coloredCell: true,
                  disabledCell: item.estimated === '',
                  ['d-flex']: true,
                  ['justify-end']: true,
                }"
              >
                {{ item.estimated }}
              </div>
            </template>
            <template v-slot:item.delta="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                  coloredCell: true,
                  greenCell: item.raw_delta >= 0,
                  redCell: item.raw_delta < 0,
                  ['d-flex']: true,
                  ['justify-end']: true,
                }"
              >
                {{ item.delta }}
              </div>
            </template>
            <template v-slot:item.previous="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                }"
              >
                {{ item.previous }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-subtitle>Sessions</v-card-subtitle>
        <v-card-text>
          <v-data-table
            locale="fr-FR"
            :headers="headersSessions"
            :items="itemsSessions[site_id_key(site.id)]"
            :loading="loading"
            class="elevation-1"
            :hide-default-footer="true"
            :disable-pagination="true"
            :disable-filtering="true"
            :disable-sort="true"
          >
            <template v-slot:item.month="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                }"
              >
                {{ item.month }}
              </div>
            </template>
            <template v-slot:item.objective="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                }"
              >
                {{ item.objective }}
              </div>
            </template>
            <template v-slot:item.value="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                }"
              >
                {{ item.value }}
              </div>
            </template>
            <template v-slot:item.estimated="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                  coloredCell: true,
                  disabledCell: item.estimated === '',
                  ['d-flex']: true,
                  ['justify-end']: true,
                }"
              >
                {{ item.estimated }}
              </div>
            </template>
            <template v-slot:item.percent="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                  coloredCell: true,
                  greenCell: item.raw_percent >= 1,
                  redCell: item.raw_percent < 1,
                  ['d-flex']: true,
                  ['justify-end']: true,
                }"
              >
                {{ item.percent }}
              </div>
            </template>
            <template v-slot:item.previous="{ item }">
              <div
                :class="{
                  boldCell: item.month === 'Total',
                }"
              >
                {{ item.previous }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-subtitle>RPM</v-card-subtitle>
        <v-card-text>
          <v-data-table
            locale="fr-FR"
            :headers="headersRpm"
            :items="itemsRpm[site_id_key(site.id)]"
            :loading="loading"
            class="elevation-1"
            :hide-default-footer="true"
            :disable-pagination="true"
            :disable-filtering="true"
            :disable-sort="true"
          >
            <template v-slot:item.percent="{ item }">
              <div
                :class="{
                  coloredCell: true,
                  greenCell: item.raw_percent >= 1,
                  redCell: item.raw_percent < 1,
                  ['d-flex']: true,
                  ['justify-end']: true,
                }"
              >
                {{ item.percent }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <dot-menu
      position="page-top-right"
      :isLoading="isLoading"
      :pdfButton="{
        getContainerRef: () => $refs.pdfContainer,
        filename: 'monthly_achievements.pdf',
      }"
    />
  </div>
</template>

<script>
import DotMenu from "@/components/common/menus/DotMenu";
import Filters from "@/components/premium/monthly-achievements/filters/Filters";

import useAxios from "@/hooks/useAxios";
import { getDateDetails, getDateStr, monthsFrArray } from "@/utils/dates";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "@/utils/formatting";
import { saveRmraView } from "@/utils/rmr-analytics";

const site_id_key = (site_id) => `site_${site_id.toString()}`;
const monthName = (date) => {
  const [yyyy, mm, dd] = date.split("-");

  return monthsFrArray[parseInt(mm, 10) - 1];
};
const currentMonthName = monthName(getDateStr());
const dateDetails = getDateDetails();

export default {
  name: "MonthlyAchievements",
  components: {
    DotMenu,
    Filters,
  },
  data() {
    return {
      site_id_key,
      loading: false,
      objectives: {},
      headersCa: [
        {
          text: "Mois",
          value: "month",
        },
        {
          text: "Objectif",
          value: "objective",
          align: "end",
        },
        {
          text: "Réalisé",
          value: "value",
          align: "end",
        },
        {
          text: "Estimé fin de mois",
          value: "estimated",
          align: "end",
        },
        {
          text: "Variation",
          value: "delta",
          align: "end",
        },
        {
          text: "N-1",
          value: "previous",
          align: "end",
        },
      ],
      itemsCa: {},
      headersSessions: [
        {
          text: "Mois",
          value: "month",
        },
        {
          text: "Objectif",
          value: "objective",
          align: "end",
        },
        {
          text: "Réalisées",
          value: "value",
          align: "end",
        },
        {
          text: "Estimées fin de mois",
          value: "estimated",
          align: "end",
        },
        {
          text: "% Objectif",
          value: "percent",
          align: "end",
        },
        {
          text: "N-1",
          value: "previous",
          align: "end",
        },
      ],
      itemsSessions: {},
      headersRpm: [
        {
          text: "Mois",
          value: "month",
        },
        {
          text: "Objectif",
          value: "objective",
          align: "end",
        },
        {
          text: "Réalisé",
          value: "value",
          align: "end",
        },
        {
          text: "% Objectif",
          value: "percent",
          align: "end",
        },
        {
          text: "N-1",
          value: "previous",
          align: "end",
        },
      ],
      itemsRpm: {},
    };
  },
  setup() {
    const { isLoading, axiosGet } = useAxios();

    return {
      isLoading,
      axiosGet,
    };
  },
  async created() {
    await this.getData();
  },
  mounted() {
    saveRmraView(this.$route);
  },
  methods: {
    new_ca_item(month, field = "", value = 0) {
      return {
        month,
        type: "ca",
        objective: field === "objective" ? formatCurrency(value, 0) : 0,
        value: field === "value" ? formatCurrency(value, 0) : 0,
        estimated: field === "estimated" ? formatCurrency(value, 0) : "",
        delta: field === "delta" ? formatCurrency(value, 0) : 0,
        raw_objective: field === "objective" ? value : 0,
        raw_value: field === "value" ? value : 0,
        raw_estimated: field === "estimated" ? value : 0,
        raw_delta: field === "delta" ? value : 0,
        previous: field === "previous" ? formatCurrency(value, 0) : 0,
        raw_previous: field === "previous" ? value : 0,
      };
    },
    new_session_item(month, field = "", value = 0) {
      return {
        month,
        type: "sessions",
        objective:
          field === "objective" ? formatNumber(value, { nbDecimals: 0 }) : 0,
        value: field === "value" ? formatNumber(value, { nbDecimals: 0 }) : 0,
        estimated:
          field === "estimated" ? formatNumber(value, { nbDecimals: 0 }) : "",
        percent: field === "percent" ? formatPercent(value, 0) : 0,
        raw_objective: field === "objective" ? value : 0,
        raw_value: field === "value" ? value : 0,
        raw_estimated: field === "estimated" ? value : 0,
        raw_percent: field === "percent" ? value : 0,
        previous:
          field === "previous" ? formatNumber(value, { nbDecimals: 0 }) : 0,
        raw_previous: field === "previous" ? value : 0,
      };
    },
    new_rpm_item(month, field = "", value = 0) {
      return {
        month,
        type: "rpm",
        objective: field === "objective" ? formatCurrency(value, 0) : 0,
        value: field === "value" ? formatCurrency(value, 0) : 0,
        percent: field === "percent" ? formatPercent(value, 0) : 0,
        raw_objective: field === "objective" ? value : 0,
        raw_value: field === "value" ? value : 0,
        raw_percent: field === "percent" ? value : 0,
        previous: field === "previous" ? formatCurrency(value, 0) : 0,
        raw_previous: field === "previous" ? value : 0,
      };
    },
    update_estimated_column(item, month, field) {
      if (
        month === currentMonthName &&
        ["objective", "value"].includes(field) &&
        Object.keys(item).includes("estimated")
      ) {
        const { day, nbDaysInMonth } = dateDetails;
        const estimated =
          (item["raw_value"] / Math.max(day - 1, 1)) * nbDaysInMonth;
        item["raw_estimated"] = Math.round(estimated);
        item["estimated"] =
          item.type === "sessions"
            ? formatNumber(estimated, { nbDecimals: 0 })
            : formatCurrency(estimated, 0);
      }
    },
    update_delta_column(item, field) {
      if (
        ["objective", "value"].includes(field) &&
        Object.keys(item).includes("delta")
      ) {
        const delta =
          (item["raw_estimated"] ? item["raw_estimated"] : item["raw_value"]) -
          item["raw_objective"];
        item["delta"] = formatCurrency(delta, 0);
        item["raw_delta"] = delta;
      }
    },
    update_percent_column(item, field) {
      if (
        ["objective", "value"].includes(field) &&
        Object.keys(item).includes("percent")
      ) {
        const percent =
          (item["raw_estimated"] ? item["raw_estimated"] : item["raw_value"]) /
          Math.max(item["raw_objective"], 1);
        item["percent"] = formatPercent(percent, 0);
        item["raw_percent"] = percent;
      }
    },
    create_or_update_item(itemList, month, item, field = "", value = 0) {
      const index = itemList.findIndex((entry) => entry.month === month);
      if (index >= 0) {
        itemList[index][`raw_${field}`] = value;
        itemList[index][field] =
          item["type"] === "sessions"
            ? formatNumber(value, { nbDecimals: 0 })
            : formatCurrency(value, 0);
        this.update_estimated_column(itemList[index], month, field);
        this.update_delta_column(itemList[index], field);
        this.update_percent_column(itemList[index], field);
      } else {
        itemList.push(item);
      }
    },
    collect_ca_objectives(objectives) {
      for (const objectif of objectives) {
        const key = site_id_key(objectif.site_id);
        const month = monthName(objectif.date);
        const item = this.new_ca_item(month, "objective", objectif.value);
        if (!Object.keys(this.itemsCa).includes(key)) {
          this.itemsCa[key] = [item];
          continue;
        }

        this.create_or_update_item(
          this.itemsCa[key],
          month,
          item,
          "objective",
          objectif.value
        );
      }
    },
    collect_sessions_objectives(objectives) {
      for (const objectif of objectives) {
        const key = site_id_key(objectif.site_id);
        const month = monthName(objectif.date);
        const item = this.new_session_item(month, "objective", objectif.value);
        if (!Object.keys(this.itemsSessions).includes(key)) {
          this.itemsSessions[key] = [item];
          continue;
        }

        this.create_or_update_item(
          this.itemsSessions[key],
          month,
          item,
          "objective",
          objectif.value
        );
      }
    },
    collect_rpm_objectives(objectives) {
      for (const objectif of objectives) {
        const key = site_id_key(objectif.site_id);
        const month = monthName(objectif.date);
        const item = this.new_rpm_item(month, "objective", objectif.value);
        if (!Object.keys(this.itemsRpm).includes(key)) {
          this.itemsRpm[key] = [item];
          continue;
        }

        this.create_or_update_item(
          this.itemsRpm[key],
          month,
          item,
          "objective",
          objectif.value
        );
      }
    },
    collect_ca_achieved(achievements) {
      for (const achievement of achievements) {
        const key = site_id_key(achievement.site_id);
        const month = monthName(achievement.date);
        const item = this.new_ca_item(month, "value", achievement.value);
        if (!Object.keys(this.itemsCa).includes(key)) {
          this.itemsCa[key] = [item];

          continue;
        }

        this.create_or_update_item(
          this.itemsCa[key],
          month,
          item,
          "value",
          achievement.value
        );
      }
    },
    collect_sessions_achieved(achievements) {
      for (const achievement of achievements) {
        const key = site_id_key(achievement.site_id);
        const month = monthName(achievement.date);
        const item = this.new_session_item(month, "value", achievement.value);
        if (!Object.keys(this.itemsSessions).includes(key)) {
          this.itemsSessions[key] = [item];

          continue;
        }

        this.create_or_update_item(
          this.itemsSessions[key],
          month,
          item,
          "value",
          achievement.value
        );
      }
    },
    collect_rpm_achieved(achievements) {
      for (const achievement of achievements) {
        const key = site_id_key(achievement.site_id);
        const month = monthName(achievement.date);
        const item = this.new_rpm_item(month, "value", achievement.value);
        if (!Object.keys(this.itemsRpm).includes(key)) {
          this.itemsRpm[key] = [item];

          continue;
        }

        this.create_or_update_item(
          this.itemsRpm[key],
          month,
          item,
          "value",
          achievement.value
        );
      }
    },
    collect_last_ca_achieved(achievements) {
      for (const achievement of achievements) {
        const key = site_id_key(achievement.site_id);
        const month = monthName(achievement.date);
        const item = this.new_ca_item(month, "previous", achievement.value);
        if (!Object.keys(this.itemsCa).includes(key)) {
          this.itemsCa[key] = [item];

          continue;
        }

        this.create_or_update_item(
          this.itemsCa[key],
          month,
          item,
          "previous",
          achievement.value
        );
      }
    },
    collect_last_sessions_achieved(achievements) {
      for (const achievement of achievements) {
        const key = site_id_key(achievement.site_id);
        const month = monthName(achievement.date);
        const item = this.new_session_item(
          month,
          "previous",
          achievement.value
        );
        if (!Object.keys(this.itemsSessions).includes(key)) {
          this.itemsSessions[key] = [item];

          continue;
        }

        this.create_or_update_item(
          this.itemsSessions[key],
          month,
          item,
          "previous",
          achievement.value
        );
      }
    },
    collect_last_rpm_achieved(achievements) {
      for (const achievement of achievements) {
        const key = site_id_key(achievement.site_id);
        const month = monthName(achievement.date);
        const item = this.new_rpm_item(month, "previous", achievement.value);
        if (!Object.keys(this.itemsRpm).includes(key)) {
          this.itemsRpm[key] = [item];

          continue;
        }

        this.create_or_update_item(
          this.itemsRpm[key],
          month,
          item,
          "previous",
          achievement.value
        );
      }
    },
    generate_ca_totals(site_key) {
      const item = this.new_ca_item("Total");
      for (const entry of this.itemsCa[site_key]) {
        const achievements = entry["raw_value"];
        const estimated = entry["raw_estimated"];

        item["raw_objective"] += entry["raw_objective"];
        item["objective"] = formatCurrency(item["raw_objective"], 0);

        item["raw_value"] += achievements;
        item["value"] = formatCurrency(item["raw_value"], 0);

        item["raw_estimated"] += estimated;
        item["estimated"] = formatCurrency(item["raw_estimated"], 0);

        item["raw_delta"] = item["raw_value"] - item["raw_objective"];
        item["delta"] = formatCurrency(item["raw_delta"], 0);

        item["raw_previous"] += entry["raw_previous"];
        item["previous"] = formatCurrency(item["raw_previous"], 0);
      }

      this.create_or_update_item(this.itemsCa[site_key], "Total", item);
    },
    generate_sessions_totals(site_key) {
      const item = this.new_session_item("Total", "value", 0);
      for (const entry of this.itemsSessions[site_key]) {
        const achievements = entry["raw_value"];
        const estimated = entry["raw_estimated"];

        item["raw_objective"] += entry["raw_objective"];
        item["objective"] = formatNumber(item["raw_objective"], {
          nbDecimals: 0,
        });

        item["raw_value"] += achievements;
        item["value"] = formatNumber(item["raw_value"], { nbDecimals: 0 });

        item["raw_estimated"] += estimated;
        item["estimated"] = formatNumber(item["raw_estimated"], {
          nbDecimals: 0,
        });

        item["raw_percent"] =
          item["raw_value"] / Math.max(item["raw_objective"], 1);
        item["percent"] = formatPercent(item["raw_percent"], 0);

        item["raw_previous"] += entry["raw_previous"];
        item["previous"] = formatNumber(item["raw_previous"], {
          nbDecimals: 0,
        });
      }

      this.create_or_update_item(this.itemsSessions[site_key], "Total", item);
    },
    generate_totals() {
      for (const site_key of Object.keys(this.itemsCa)) {
        this.generate_ca_totals(site_key);
      }

      for (const site_key of Object.keys(this.itemsSessions)) {
        this.generate_sessions_totals(site_key);
      }
    },
    async getData() {
      if (this.siteIds.length === 0 || Object.keys(this.itemsCa).length > 0) {
        return;
      }

      this.loading = true;

      // Reset data:
      this.itemsCa = {};
      this.itemsSessions = {};
      this.itemsRpm = {};

      // Load from endpoint:
      const {
        data: {
          objectives: { ca: ca_obj, sessions: sessions_obj, rpm: rpm_obj },
          achieved: { ca: ca_done, sessions: sessions_done, rpm: rpm_done },
          ["n-1"]: { ca: ca_last, sessions: sessions_last, rpm: rpm_last },
        },
      } = await this.axiosGet("/programmatic/weekly_report/", {
        site_ids: this.siteIds.join(","),
      });

      this.collect_ca_objectives(ca_obj);
      this.collect_sessions_objectives(sessions_obj);
      this.collect_rpm_objectives(rpm_obj);

      this.collect_ca_achieved(ca_done);
      this.collect_sessions_achieved(sessions_done);
      this.collect_rpm_achieved(rpm_done);

      this.collect_last_ca_achieved(ca_last);
      this.collect_last_sessions_achieved(sessions_last);
      this.collect_last_rpm_achieved(rpm_last);

      this.generate_totals();

      this.loading = false;
    },
  },
  computed: {
    siteList() {
      return this.$store.getters["common/getSiteList"];
    },
    siteIds() {
      return this.$store.getters["premium/getSites"];
    },
    sites() {
      return this.siteIds.map((site_id) =>
        this.siteList.find((site) => site.id === site_id)
      );
    },
  },
  watch: {
    siteIds() {
      this.getData();
    },
  },
};
</script>

<style scoped>
.pdfContainer {
  position: relative;
}

.boldCell {
  font-weight: bold;
}

.coloredCell {
  height: 100%;
  padding: 0.75rem;
}

.disabledCell {
  background-color: lightgrey;
}

.greenCell {
  background-color: #c5d86d;
}

.redCell {
  background-color: #f9a3a4;
}
</style>
